import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Welcome from './Welcome';
import HomePage from './HomePage';
import Slide from '../../components/Slide';

const WelcomeScreen = () => {
  const history = useHistory();

  useEffect(() => {
    let mounted = true;
    const timer = setTimeout(() => {
      if (mounted) history.push('/home');
    }, 7000);

    return () => {
      mounted = false;
      if (timer) clearTimeout(timer);
    };

    // eslint-disable-next-line
  }, []);

  return <Slide Primary={Welcome} Secondary={HomePage} timeout={2000} move={true} direction='right' />;
};

export default WelcomeScreen;
