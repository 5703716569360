import { useEffect } from 'react';
import firebase from 'firebase/app';
import cuid from 'cuid';

import { firestore } from '../firebase/init';

const useUniqueUser = () => {
  useEffect(() => {
    try {
      if (!window.localStorage || window?.location?.hostname === 'localhost') return;

      // Check if user is returning
      const isReturning = Boolean(window.localStorage.getItem('inieto-unique-visit'));
      if (Boolean(isReturning)) return;

      const visitId = cuid();
      // Report visit
      firestore
        .collection('visits')
        .doc(visitId)
        .set({
          date: firebase.firestore.Timestamp.now(),
          id: visitId,
        })
        .catch(console.error);

      window.localStorage.setItem('inieto-unique-visit', visitId);
    } catch (error) {
      console.error(error);
    }
  }, []);
};

export default useUniqueUser;
