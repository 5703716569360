import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import useMobile from '../../hooks/useMobile';

import Module from '../../components/Module';
import WorkHistory from '../WorkHistory';
import Contact from '../Contact';
import Education from '../Education';
import Skills from '../Skills';
import PersonalProjects from '../personalProjects';

const useStyles = makeStyles({
  root: {
    margin: 'auto',
    padding: '15px',
    marginBottom: '50px',
    borderLeft: '1px solid #f2f2f2',
    width: '100%',
    minWidth: '400px',
    maxWidth: '800px',
  },
  noBorder: {
    margin: 'auto',
    padding: '15px',
    marginBottom: '50px',
    width: '100%',
    minWidth: '400px',
    maxWidth: '800px',
  },
});

const MainContent = () => {
  const classes = useStyles();
  const [, width] = useMobile();

  return (
    <Grid container justify='center' alignItems='center' className={width > 850 ? classes.root : classes.noBorder}>
      <Grid item xs={12}>
        <Module title='Contact' content={<Contact />} />
      </Grid>

      <Grid item xs={12}>
        <Module title='Work History' content={<WorkHistory />} />
      </Grid>

      <Grid item xs={12}>
        <Module title='Personal Projects' content={<PersonalProjects />} />
      </Grid>

      <Grid item xs={12}>
        <Module title='Skills' content={<Skills />} />
      </Grid>

      <Grid item xs={12}>
        <Module title='Education' content={<Education />} />
      </Grid>
    </Grid>
  );
};
export default MainContent;
