import React from "react";
import "./styles.css";

const RenderWorkHistory = ({
  title,
  location,
  from,
  to,
  descriptionText,
  descriptionBullets,
  link,
}) => {
  return (
    <div className="root">
      <div className="title">
        <p>
          <strong>{title}</strong>
        </p>
      </div>

      {link && (
        <div className="link">
          {link.linkTo && (
            <a href={link.linkTo} rel="noopener noreferrer" target="_blank">
              {link.value}{" "}
            </a>
          )}
          {!link.linkTo && <p>{link.value}</p>}
        </div>
      )}

      {location && (
        <div className="location">
          <p>{location}</p>
        </div>
      )}

      {from && to && (
        <div className="date">
          <p>
            {from} - {to}
          </p>
        </div>
      )}

      <div className="description-text">
        <p>{descriptionText}</p>
      </div>

      {descriptionBullets && (
        <div className="description-bullet-list">
          <ul>
            {descriptionBullets.map((e) => (
              <li key={e}>{e}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default RenderWorkHistory;
