import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      text: {
        color: "#f2f2f2",
        border: "1px solid white",
        borderRadius: 7,
        margin: "5px",
        borderImageSlice: 1,
        borderImage:
          "-webkit-linear-gradient(0deg, #9867F0 -6.8%, #ED4E50 84.14%)",
        "-webkit-background-clip": "text",
      },
    },
    MuiTypography: {
      h1: {
        color: "#f2f2f2",
      },
      h2: {
        color: "#f2f2f2",
      },
      h3: {
        color: "#f2f2f2",
      },
      h4: {
        background:
          "-webkit-linear-gradient(0deg, #9867F0 -6.8%, #ED4E50 84.14%)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        fontWeight: "600",
      },
      h5: {
        color: "#f2f2f2",
      },
      h6: {
        color: "#f2f2f2",
      },
      body1: {
        color: "#f2f2f2",
      },
      body2: {
        color: "#f2f2f2",
      },
      caption: {
        color: "#fff",
      },
      button: {
        color: "#00ff00",
      },
    },
    MuiInputAdornment: {
      root: {},
    },
  },
});

export default theme;
