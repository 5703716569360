import { useState, useEffect } from 'react';

const useMobileDetect = () => {
  const [isMobile, setIsMobile] = useState(Boolean(window?.screen.availWidth < 500));
  const [height, setHeight] = useState(window?.screen.availHeight);
  const [width, setWidth] = useState(window?.screen.availWidth);

  const handleResize = () => {
    try {
      if (window?.screen.availWidth < 500) setIsMobile(true);
      else setIsMobile(false);
      setHeight(window?.screen.availHeight);
      setWidth(window?.screen.availWidth);
    } catch (error) {}
  };

  useEffect(() => {
    try {
      window.addEventListener('resize', handleResize);
    } catch (error) {}
    return () => {
      try {
        window.removeEventListener('resize', handleResize);
      } catch (error) {}
    };
  }, []);

  return [isMobile, width, height];
};

export default useMobileDetect;
