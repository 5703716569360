import React from "react";
import "./styles.css";

const sections = [
  {
    label: "Phone",
    value: "575 545-3237",
    linkTo: 'tel:575-545-3237',
  },
  {
    label: "Email",
    value: "ivan@inieto.dev",
    linkTo: 'mailto:ivan@inieto.dev',
  },
  {
    label: "LinkedIn",
    value: "www.linkedin.com/in/ivan-nieto-4b516319a",
    linkTo: 'https://www.linkedin.com/in/ivan-nieto-4b516319a/'
  },
];

const Contact = () => {
  return (
    <div className="container">
      {sections.map((section) => (
        <div className="section" key={section.label}>
          <div className="section-top">
            <p>
              <strong>{section.label}</strong>
            </p>
          </div>
          <div className="section-bottom">
            {section.linkTo && (
              <a href={section.linkTo} rel="noopener noreferrer" target="_blank">{section.value} </a>
            )}
            {!section.linkTo && (
            <p>{section.value}</p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Contact;
