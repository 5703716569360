import React from "react";
import "./styles.css";

const sections = [
  {
    label: "New Mexico State University",
    value: "",
  },
  {
    label: "Bachelor of Art :",
    value: "Computer Science",
  },
  {
    label: "GPA :",
    value: "3.4",
  },
];

const Education = () => {
  return (
    <div className="education-root">
      {sections.map((section) => (
        <div key={section.label} className="education-section">
          <p>
            <strong>{section.label} </strong> {section.value}
          </p>
        </div>
      ))}
    </div>
  );
};

export default Education;
