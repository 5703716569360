import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useSpring, animated } from 'react-spring';

import MainContent from '../mainContent';

const Home = () => {
  const props = useSpring({ from: { opacity: 0 }, to: { opacity: 1 } });

  return (
    <animated.div style={props}>
      <Grid container justify='center' alignItems='center'>
        <Grid item xs={12}>
          <MainContent />
        </Grid>
      </Grid>
    </animated.div>
  );
};

export default Home;
