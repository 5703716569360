import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ButtonGroup from '../ButtonGroup';

const useStyles = makeStyles({
  root: {
    width: '100%',
    minHeight: '75px',
    maxHeight: '100px',
    flex: 1,
    position: 'static',
    marginBottom: '15px',
  },
  links: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '15px',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '15px',
  },
});

const Nav = () => {
  const classes = useStyles();
  const history = useHistory();

  const buttons = [
    { label: 'Home', onClick: () => history.push('/home') },
    { label: 'Projects', onClick: () => history.push('/projects') },
    {
      label: 'GitHub',
      onClick: () => window.open('https://github.com/Ivan-Nieto'),
    },
  ];

  return (
    <Grid container className={classes.root}>
      <Grid item xs={6} className={classes.logo}>
        <Typography variant='h4' onClick={() => history.push('/home')}>
          Ivan Nieto
        </Typography>
      </Grid>
      <Grid item xs={6} className={classes.links}>
        <ButtonGroup direction='row' size='large' buttons={buttons} />
      </Grid>
    </Grid>
  );
};

export default Nav;
