import React from 'react';
import './styles.css';

import RenderWorkHistory from '../../components/Module/RenderWorkHistory';

const jobs = [
  {
    title: 'Photo Sharing Website',
    link: {
      label: 'www.fotogroove.com',
      value: 'www.fotogroove.com',
      linkTo: 'https://www.fotogroove.com',
    },
    descriptionText:
      'Photography is one of my many hobbies, so I created a website to show off my work. www.fotogroove.com is a React website hosted on GCP that allows users to show off their photos through a gallery style UI. You can also create an account and view, comment, like and favorite other users images.',
  },
  {
    title: 'Resume Website',
    link: {
      label: 'www.inieto.dev',
      value: 'www.inieto.dev',
      linkTo: 'https://www.inieto.dev',
    },
    descriptionText:
      'To gain experience with GCP, I created a website version of my resume, www.inieto.dev . I created the front end of the site using React and hosted it on GCP using AppEngine. I purchased and configured my domain with Google Domains and later used the experience I gained with this project to build a production website at Electronic Caregiver',
  },
];

const PersonalProjects = () => {
  return (
    <div>
      {jobs.map((job, index) => (
        <div className='root' key={`Project#${index}${Math.random()}`}>
          <RenderWorkHistory {...job} />
        </div>
      ))}
    </div>
  );
};

export default PersonalProjects;
