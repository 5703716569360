import React from "react";
import "./styles.css";

const skillList = [
  "TypeScript",
  "JavaScript",
  "Google Cloud",
  "Firebase",
  "Git",
  "Java",
  "C++",
  "C",
  "Python",
  "MySQL",
  "AWS,",
];

const Skills = () => {
  return (
    <div>
      <div>
        <p>
          Experience in full stack development of web applications built with
          React and Typescript, deployed with Google Cloud Platform services
          including Firebase.
        </p>
      </div>
      <div className="skills-list">
        <ul>
          {skillList.map((skl) => (
            <li className="skills-list-item" key={skl}>
              {skl}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Skills;
