import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    position: "fixed",
    top: "40%",
    left: "50%",
    marginTop: "-50px",
    marginLeft: "-350px",
    width: "700px",
    fontFamily: "Architects Daughter cursive",
  },
});

const HomePage = () => {
  const { root } = useStyles();
  return (
    <Grid container alignItems="center" justify="center">
      <Grid item xs={12} className={root}>
        <Typography variant="h4">
          Check out my website, and while your at it you can also check out my
          GitHub for all my personal projects!
        </Typography>
      </Grid>
    </Grid>
  );
};

export default HomePage;
