import React from 'react';
import { withRouter } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';

import Home from '../home/index.tsx';
import WelcomeScreen from '../welcomeScreen';
import Projects from '../projects/Projects.tsx';
import Nav from '../../components/Nav';

const WithNav = ({ Component }) => {
  return (
    <>
      <Nav />
      <Component />
    </>
  );
};

const Router = () => {
  return (
    <Switch>
      <Route exact path='/home' component={() => <WithNav Component={Home} />} />
      <Route exact path='/projects' component={() => <WithNav Component={Projects} />} />
      <Route exact path='/' component={WelcomeScreen} />
      <Route>
        <Redirect to='/home' />
      </Route>
    </Switch>
  );
};

export default withRouter(Router);
