import React, { useState, useEffect, useRef } from 'react';
import { useTransition, animated } from 'react-spring';
import './styles.css';

const Slide = ({ Primary, Secondary, timeout, move, direction }) => {
  const [index, set] = useState(0);
  const ref = useRef(null);

  const pages = [
    ({ style }) => (
      <animated.div style={style}>
        <Primary />
      </animated.div>
    ),
    ({ style }) => (
      <animated.div style={style}>
        <Secondary />
      </animated.div>
    ),
  ];

  const transitionRight = useTransition(index, (p) => p, {
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
  });

  const transitionUp = useTransition(index, (p) => p, {
    from: { opacity: 0, transform: 'translate3d(0,100%,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0%,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,-50%,0)' },
  });

  const transition = direction === 'up' ? transitionUp : transitionRight;

  useEffect(() => {
    let mounted = true;

    if (!ref.current || ref.current.length === 0) ref.current = [];

    if (timeout > 0 && move) {
      ref.current.push(
        setTimeout(() => {
          if (mounted) set(1);
        }, timeout)
      );
    }

    return () => {
      mounted = false;
      if (ref.current) ref.current.forEach((e) => clearTimeout(e));
    };
  }, [timeout, move]);

  return (
    <div className='simple-trans-main'>
      {transition.map(({ item, props }, idx) => {
        const Page = pages[item];
        return <Page key={`${idx}-slide-page`} style={props} />;
      })}
    </div>
  );
};

export default Slide;
