import React, { useEffect, useState } from 'react';
import { Paper, Typography } from '@material-ui/core';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import './styles.css';

const GitHubProject = ({ repo }: { repo: string }) => {
  const [readMe, setReadMe] = useState('');
  const [title, setTitle] = useState();

  useEffect(() => {
    const getRepo = async () => {
      const response = await axios
        .get(`https://api.github.com/repos/Ivan-Nieto/${repo}`)
        .then((response) => {
          setTitle(response.data?.name);
          return response.data;
        })
        .catch(console.error);

      if (response?.default_branch)
        await axios
          .get(`https://raw.githubusercontent.com/Ivan-Nieto/${repo}/${response?.default_branch}/README.md`)
          .then((res) => {
            setReadMe(res.data);
          })
          .catch(console.error);
    };

    getRepo();
  }, [repo]);

  const handleClick = () => {
    const win = window.open(`https://github.com/Ivan-Nieto/${repo}`);
    win?.focus();
  };

  return (
    <div className='github-projects-root'>
      <Paper classes={{ root: 'card' }} onClick={handleClick}>
        <Typography variant='h4'>{title}</Typography>
        <div className='markdown'>
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{readMe}</ReactMarkdown>
        </div>
      </Paper>
    </div>
  );
};

export default GitHubProject;
