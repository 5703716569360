import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyCtQtbxWYYw3cSwUwH_XRkR6lQ0Ups0w1I',
  authDomain: 'resume-275522.firebaseapp.com',
  databaseURL: 'https://resume-275522.firebaseio.com',
  projectId: 'resume-275522',
  storageBucket: 'resume-275522.appspot.com',
  messagingSenderId: '730665822266',
  appId: '1:730665822266:web:c2719b99b60d014d4bed03',
  measurementId: 'G-170JEV1RK9',
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.functions();
