import React from 'react';
import './styles.css';

import GitHubProject from '../../components/GitHubProject/GitHubProject';

const Projects = () => {
  const repos = ['Nestjs-League-Manager-App', 'fotogroove', 'ARL_Project'];

  return (
    <div className='project-root'>
      {repos.map((e) => (
        <GitHubProject key={e} repo={e} />
      ))}
    </div>
  );
};

export default Projects;
