import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    flex: 1,
  },
  title: {
    alignItems: "flex-start",
    justifyItems: "center",
  },
  divider: {
    width: "20%",
    marginRight: "80%",
    borderBottom: "1px solid white",
    borderImage: "linear-gradient(90deg, #9867F0 -6.8%, #ED4E50 84.14%)",
    borderImageSlice: 1,
  },
});

const Module = ({ title, content }) => {
  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item xs={12} className={classes.title}>
        <Typography variant="h5">{title}</Typography>
      </Grid>
      <div className={classes.divider} />
      <Grid item xs={12}>
        {content}
      </Grid>
    </Grid>
  );
};

export default Module;
