import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import useUniqueUser from './hooks/useUniqueUser.tsx';

import './App.css';
import Router from './containers/Router';

const App = () => {
  useUniqueUser();
  return (
    <BrowserRouter>
      <div className='App'>
        <Router />
      </div>
    </BrowserRouter>
  );
};

export default App;
