import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Typing from 'react-typing-animation';

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    top: '40%',
    left: '50%',
    marginTop: '-50px',
    marginLeft: '-100px',
    maxWidth: '50%',
    width: '200px',
  },
});

const Welcome = () => {
  const { root } = useStyles();
  return (
    <Grid container alignItems='center' justify='center'>
      <Grid item xs={12} className={root}>
        <Typing hideCursor={true}>
          <Typography variant='h4'>Welcome, I'm Ivan</Typography>
        </Typing>
      </Grid>
    </Grid>
  );
};

export default Welcome;
