import React from 'react';
import './styles.css';

import RenderWorkHistory from '../../components/Module/RenderWorkHistory';

const jobs = [
  {
    title: 'The Electronic Caregiver - Software Developer',
    location: 'Las Cruces, NM',
    from: '11/2019',
    to: 'Current',
    descriptionText:
      'Main responsibilities include developing a React application built using Google Cloud Platform services as well as creating and maintaining supporting infrastructure relevant to the project including building REST APIs using both GCP cloud functions and an Express API lambda on AWS',
    descriptionBullets: ['Developing React applications', 'Designing and building APIs', 'Maintaining and debugging internal tools/services'],
  },
  {
    title: 'De La Paz Cleaning & Rental Service - Janitor',
    location: 'Columbus, NM',
    from: '09/2018',
    to: '03/01/2020',
    descriptionText: 'Cleaning and maintaining the interior of Border Patrol stations as well as cleaning their outdoor yards.',
    descriptionBullets: ['Indoor cleaning and yard maintenance for Border Patrol facilities'],
  },
];

const WorkHistory = () => {
  return (
    <div>
      {jobs.map((job, index) => (
        <div className='root' key={`Job#${index}${Math.random()}`}>
          <RenderWorkHistory {...job} />
        </div>
      ))}
    </div>
  );
};

export default WorkHistory;
