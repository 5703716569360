import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  row: {
    display: "inline",
    alignItems: "center",
    justify: "center",
  },
  column: {
    display: "column",
    alignItems: "center",
    justify: "center",
  },
});

const ButtonGroup = ({ size, buttons, direction }) => {
  const classes = useStyles();

  return (
    <div>
      {buttons.map((b) => (
        <Button
          size={size}
          key={b.label}
          onClick={b.onClick}
          className={direction === "row" ? classes.row : classes.column}
        >
          {b.label}
        </Button>
      ))}
    </div>
  );
};

export default ButtonGroup;
